import Nano from "nano-js";
window.Nano = Nano;

Nano.Element.setPrefix('sx');

// require('./elements/form-visibility');
// require('./elements/touch-spin');
// require('./elements/datepicker');
// require('./elements/chosen');
// require('./elements/flickity');
// require('./elements/light-gallery');

import UIkit from "uikit/dist/js/uikit";
window.UIkit = UIkit;


$(document).ready(() => {
    $('select').chosen();
});
